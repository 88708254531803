import WebpackerReact from "webpacker-react";

import "./token";
import Hello from "../components/hello";

import AbstractBulkLoadEntityForm from "../components/abstract/bulk/load/entity/form";
import AbstractBulkLoadDocumentForm from "../components/abstract/bulk/load/document/form";
import AbstractBulkRequestDocumentForm from "../components/abstract/bulk/request/document/form";
import AbstractCheckListProcessForm from "../components/abstract/check_list/process/form";
import LaboralBulkLoadHiringDocumentForm from "../components/laboral/bulk/load/hiring_document/form";
import LaboralBulkLoadEmployeeForm from "../components/laboral/bulk/load/employee/form";
import LaboralBulkLoadHiringForm from "../components/laboral/bulk/load/hiring/form";
import LaboralBulkRequestHiringDocumentForm from "../components/laboral/bulk/request/hiring_document/form";
import CorporateBulkRequestAgreementDocumentForm from "../components/corporate/bulk/request/agreement_document/form";
import LaboralBulkNotificationSignForm from "../components/laboral/bulk/notification/sign/form";
import CorporateBulkNotificationSignForm from "../components/corporate/bulk/notification/sign/form";
// import DocumentForm from "../components/document/form";
import BulkLoadTagForm from "../components/tag/form";
import HiringComplianceProcessForm from "../components/laboral/hiring_compliance_process/Form";
// import ControldocFormTemplateForm from "../components/controldoc_form/template/form";
import ControldocFormTemplateMappingLinkForm from "../components/controldoc_form/template_mapping_link/form";
import DocumentTypeForm from "../components/document_type/form";
import LaboralBulkRequestZipDocumentForm from "../components/laboral/bulk/request/zip/document/form";
import LaboralCheckListHiringProcessForm from '../components/laboral/check_list/hiring_process/form';
import CorporateCheckListAgreementProcessForm from '../components/corporate/check_list/agreement_process/form';
import LaboralBulkRequestDestroyEmployeeForm from "../components/laboral/bulk/request/destroy_employee/form";
import LaboralBulkRequestDestroyDocumentForm from "../components/laboral/bulk/request/destroy_document/form";
import ControldocSignDocumentMetaInformationForm from "../components/controldoc_sign/document/meta_information/form";

// Laboral
import LaboralHiringDocumentForm from '../components/laboral/hiring_document/form';
import LaboralEmployeeDocumentForm from '../components/laboral/employee_document/form';
import LaboralControldocFormTemplateForm from "../components/laboral/controldoc_form/template/form";
import LaboralDocumentTypeForm from "../components/laboral/document_type/form";
import LaboralControldocFormTemplateLayoutForm from "../components/laboral/controldoc_form/template_layout/form";
import LaboralCheckListSharedProcessForm from "../components/laboral/check_list/shared_process/form"
import LaboralCustomFieldOrderFormList from "../components/laboral/custom_field/order_form_list";
import LaboralShortlistMappingConfigForm from '../components/laboral/shortlist/mapping_config/form';
import LaboralBulkNotificationFillForm from "../components/laboral/bulk/notification/fill/form";
// Corporate
import CorporateAgreementDocumentForm from '../components/corporate/agreement_document/form'
import CorporateControldocFormTemplateForm from "../components/corporate/controldoc_form/template/form";
import CorporateControldocFormTemplateLayoutForm from "../components/corporate/controldoc_form/template_layout/form";
import CorporateDocumentTypeForm from "../components/corporate/document_type/form";
import CorporateCheckListSharedProcessForm from "../components/corporate/check_list/shared_process/form"
import CorporateBulkRequestZipDocumentForm from "../components/corporate/bulk/request/zip/document/form";

// Abstract
import AbstractControldocFormTemplateForm from "../components/abstract/controldoc_form/template/form";
import AbstractFaoMappingConfigForm from '../components/abstract/fao/mapping_config/form';
import AbstractDocumentForm from '../components/abstract/document/form';
import AbstractDocumentTypeForm from "../components/abstract/document_type/form";
import AbstractControldocFormTemplateLayoutForm from "../components/abstract/controldoc_form/template_layout/form";
import AbstractCheckListSharedProcessForm from "../components/abstract/check_list/shared_process/form"
import AbstractBulkRequestZipDocumentForm from "../components/abstract/bulk/request/zip/document/form"
import AbstractEntityForm from "../components/abstract/entity/form";
import AbstractCustomFieldOrderFormList from "../components/abstract/custom_field/order_form_list";
import AbstractEntityFolderCheckListForm from '../components/abstract/entity_folder/check_list/form'
import AbstractEntityFolderCheckListSharedForm from '../components/abstract/entity_folder/check_list_shared/form'
import AbstractEntityFolderCheckListRequirementForm from '../components/abstract/entity_folder/check_list_requirement/form'

import AbstractWorkflowBasicForm from '../components/abstract/workflow/process/basic_form';
import AbstractWorkflowRequirementForm from '../components/abstract/workflow/requirement/form';
import AbstractWorkflowRequirementCommentBox from '../components/abstract/workflow/requirement/comment/box';
import AbstractWorkflowRequirementStageShortlistInputForm from '../components/abstract/workflow/requirement_stage/shortlist/form';
import AbstractWorkflowRequirementStageToolbarEvent from '../components/abstract/workflow/requirement_stage/toolbar_event';


// Karin Law
import KarinLawDocumentTypeForm from '../components/karin_law/document_type/form';
import KarinLawWorkflowBasicForm from '../components/karin_law/workflow/process/basic_form';
import KarinLawWorkflowRequirementForm from '../components/karin_law/workflow/requirement/form'
import KarinLawWorkflowRequirementCommentBox from '../components/karin_law/workflow/requirement/comment/box';
import KarinLawWorkflowRequirementStageToolbarEvent from '../components/karin_law/workflow/requirement_stage/toolbar_event';


// Common
import NpsResultModalForm from '../components/nps/result/modal_form';
import CheckListSharedProcessForm from '../components/check_list/shared_process/form';
import OtpInputForm from '../components/common/otp_input_form';
import WorkflowRequirementRoadMap from '../components/workflow/requirement/roadmap'
import CustomFieldForm from "../components/custom_field/form"

import WorkflowProvider from '../components/workflow/provider';

// Portal Access
import PortalAccessDocumentBulkDocumentSignForm from '../components/portal_access/document/bulk_document_sign_form'

// New Portal
import PortalWorkflowRequirementCommentBox from '../components/portal/workflow/requirement/comment/box';
import PortalWorkflowRequirementStageToolbarEvent from '../components/portal/workflow/requirement_stage/toolbar_event';
import PortalWorkflowRequirementForm from '../components/portal/workflow/requirement/form';

// Public
import PublicWorkflowRequirementForm from '../components/public/workflow/requirement/form'
import PublicWorkflowRequirementCommentBox from '../components/public/workflow/requirement/comment/box';
import PublicTutorialTourVirtualTour from "../components/public/tutorial/tour/virtual_tour";

// CustomerOnboardingProcess
import CustomerOnboardingProcessAcceptComercialCondictionsProducts from '../components/customer_onboarding_process/accept_comercial_conditions_step/accept_products'

WebpackerReact.setup({
  AbstractBulkLoadEntityForm,
  AbstractBulkLoadDocumentForm,
  AbstractBulkRequestDocumentForm,
  AbstractCheckListProcessForm,
  LaboralBulkLoadHiringDocumentForm,
  LaboralBulkLoadEmployeeForm,
  LaboralBulkLoadHiringForm,
  CorporateBulkRequestAgreementDocumentForm,
  // DocumentForm,
  BulkLoadTagForm,
  LaboralBulkRequestHiringDocumentForm,
  LaboralBulkNotificationSignForm,
  HiringComplianceProcessForm,
  // ControldocFormTemplateForm,
  ControldocFormTemplateMappingLinkForm,
  DocumentTypeForm,
  CorporateBulkNotificationSignForm,
  LaboralBulkRequestZipDocumentForm,
  LaboralCheckListHiringProcessForm,
  CorporateCheckListAgreementProcessForm,
  LaboralBulkRequestDestroyEmployeeForm,
  LaboralBulkRequestDestroyDocumentForm,
  ControldocSignDocumentMetaInformationForm,

  // Laboral
  LaboralControldocFormTemplateForm,
  LaboralHiringDocumentForm,
  LaboralEmployeeDocumentForm,
  LaboralDocumentTypeForm,
  LaboralControldocFormTemplateLayoutForm,
  LaboralCheckListSharedProcessForm,
  LaboralCustomFieldOrderFormList,
  LaboralShortlistMappingConfigForm,
  LaboralBulkNotificationFillForm,

  // Corporate
  CorporateControldocFormTemplateForm,
  CorporateAgreementDocumentForm,
  CorporateControldocFormTemplateLayoutForm,
  CorporateDocumentTypeForm,
  CorporateCheckListSharedProcessForm,
  CorporateBulkRequestZipDocumentForm,
  // Abstract
  AbstractControldocFormTemplateForm,
  AbstractFaoMappingConfigForm,
  AbstractDocumentForm,
  AbstractDocumentTypeForm,
  AbstractControldocFormTemplateLayoutForm,
  AbstractCheckListSharedProcessForm,
  AbstractBulkRequestZipDocumentForm,
  AbstractEntityForm,
  AbstractCustomFieldOrderFormList,
  AbstractEntityFolderCheckListForm,
  AbstractEntityFolderCheckListSharedForm,
  AbstractEntityFolderCheckListRequirementForm,
  AbstractWorkflowBasicForm,
  AbstractWorkflowRequirementForm,
  AbstractWorkflowRequirementCommentBox,
  AbstractWorkflowRequirementStageShortlistInputForm,
  AbstractWorkflowRequirementStageToolbarEvent,
  // KARIN LAW
  KarinLawDocumentTypeForm,
  KarinLawWorkflowBasicForm,
  KarinLawWorkflowRequirementForm,
  KarinLawWorkflowRequirementCommentBox,
  KarinLawWorkflowRequirementStageToolbarEvent,

  // Common
  NpsResultModalForm,
  OtpInputForm,
  WorkflowProvider,
  WorkflowRequirementRoadMap,
  CustomFieldForm,

  // Portal Access
  PortalAccessDocumentBulkDocumentSignForm,

  // New Portal
  PortalWorkflowRequirementCommentBox,
  PortalWorkflowRequirementStageToolbarEvent,
  PortalWorkflowRequirementForm,

  // Public
  PublicWorkflowRequirementForm,
  PublicWorkflowRequirementCommentBox,
  PublicTutorialTourVirtualTour,

  // CustomerOnboardingProcess
  CustomerOnboardingProcessAcceptComercialCondictionsProducts
});
