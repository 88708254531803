import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
  useMemo
} from 'react';
import {
Row, 
Col } from 'reactstrap';

import ReactPlayer from 'react-player'

import CrmTutorialTourContext from '../tour/context/tour_context';
import TourControls from '../tour/tour_controls';

import {
  update as updateTourCapsuleService
} from './axios';

const tourCapsuleFormData = (formData, formName, tourCapsule) => {
  formData.append(`${ formName }[id]`, tourCapsule.id || '');
  formData.append(`${ formName }[viewed_state]`, tourCapsule.viewed_state || '');

  return formData
}

const TIMING_NEXT_CAPSULE = 2;

const PublicTutorialTourCapsuleCurrentItem = props => {
  const {
    // currentTourCapsule: tourCapsule,
    callbacks: {
      onChangeTourCapsule: callbackOnChangeTourCapsule,
      setNextCapsule: callbackSetNextCapsule
    },
    configuration: {
      tourCapsuleDefaultRequestParams
    },
    nextCapsule
  } = useContext(CrmTutorialTourContext)

  const {
    currentTourCapsule: tourCapsule
  } = props;

  const [showNextCapsuleMessage, setShowNextCapsuleMessage] = useState(false)

  const defaultRequestParams = { ... props?.configuration?.tourCapsuleDefaultRequestParams, ... { id: tourCapsule.hashid } }

  const formName = 'crm_tutorial_tour_capsule'

  const videoUrl = useMemo(() => {
    return `https://www.youtube.com/watch?v=${ tourCapsule.capsule.video_id }`
  }, [tourCapsule])

  const reactPlayerRef = useRef(null);

  const playingVideo = true

  const videoConfig = {
    youtube: {
      playerVars: {
        autoplay: 1,
        controls: 0,
        disablekb: 1,
        rel: 0,
        modestbranding: 1,
        showinfo: 0,
        iv_load_policy: 3,
      }
    }
  }

  const onPlay = useCallback(() => {
    let attributes = {
      viewed_state: 'incomplete'
    }

    if(tourCapsule.viewed_state != 'completed'){
      onUpdateTourCapsule(
        { ... tourCapsule, ... attributes }
      )
    }
  }, [])

  const onEnded = useCallback(() => {
    const attributes = {
      viewed_state: 'completed'
    }

    if(tourCapsule.viewed_state == 'completed'){
      setNextCapsuleDelay()
    } else {
      onUpdateTourCapsule(
        { ... tourCapsule, ... attributes }
      )
    }
  }, [])

  // START SUBMIT TOURCAPSULE
  const onUpdateTourCapsule = useCallback((_tourCapsule) => {
    let formData = getTourCapsuleFormData(_tourCapsule);

    updateTourCapsuleService(defaultRequestParams, formData, response => {
      if(response.status == 200){
        callbackOnChangeTourCapsule(response.data.id, response.data)

        if(response.data.viewed_state == 'completed'){
          setNextCapsuleDelay()
        }
      } else {
        console.error(response.data.errors)
      }
    })
  })

  const setNextCapsuleDelay = useCallback(() => {
    if(nextCapsule){
      setShowNextCapsuleMessage(true)
    }

    setTimeout(() => {
      callbackSetNextCapsule()
    }, TIMING_NEXT_CAPSULE * 1000);
  }, [])

  const getTourCapsuleFormData = useCallback((_tourCapsule) => {
    let formData = new FormData();

    tourCapsuleFormData(formData, formName, _tourCapsule);

    return formData;
  }, [])
  // END SUBMIT TOURCAPSULE

  const nextCapsuleMessage = () => {
    if(showNextCapsuleMessage){
      return(
        <div>
          La cápsula
          <strong>
            { ` ${ nextCapsule.capsule.name } ` }
          </strong>
          se reproducirá en 2 segundos.
        </div>
      )
    }
  }

  return(
    <div className="row py-4 px-5">
      <div className="col-12">
        <ReactPlayer
          url={ videoUrl }
          playing={ playingVideo }
          config={ videoConfig }
          controls={ false }
          volume={ 1 }
          muted={ false }
          width={ "100%" }
          onPlay={ onPlay }
          onEnded={ onEnded }
          // onDuration={ event => console.log(event) }
          ref={ reactPlayerRef }
          // onReady={ onReadyPlayer }
          className="mb-3 ratio ratio-16x9"
        />



        <Row>
          <Col lg='8' className="order-2 order-lg-1">
            { nextCapsuleMessage() }
          </Col>
          <Col lg='4' className="order-1 order-lg-2">
            <div className="text-end mb-2">
              <TourControls videoRef={reactPlayerRef} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div dangerouslySetInnerHTML={{__html: tourCapsule.capsule.description }}></div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PublicTutorialTourCapsuleCurrentItem;
