import React, { useState } from "react";
import useFilterrific from "./useFilterrific";
import _ from "lodash";

const useFilterRequestParams = (props) => {
  let [requestParams, setRequestParams] = useState({'filterrific': {}});

  const setFilterRequestParams = (params) => {
    let { type, args, bootstrapTable } = params
    let _requestParams = {...requestParams}

    if (type == 'pagination'){
      _requestParams['page'] = args?.page || 1;
      _requestParams['per_page'] = args?.sizePerPage || 10;

    } else if(type == 'filter'){
      _.each(bootstrapTable.current.props.columns, function(column){
        let dataField = column.dataField;
        let filterByFilterrific = column.filterByFilterrific;

        let isCustomField = _.includes(dataField, "custom_fields.")
        let filter = _.find(args.filters, (value, key) =>{
          return key == dataField
        })

        if(isCustomField && filterByFilterrific){
          let customFieldKey = _.last(_.split(filterByFilterrific, '.'))
          let context = _.first(_.split(filterByFilterrific, '.'))
          let customFieldQuery = {}
          let search_custom_fields = {}

          customFieldQuery[customFieldKey] = filter?.filterVal || ''

          if (context == 'abstract'){
            search_custom_fields['search_by_key_abstract_custom_fields'] = customFieldQuery
          } else if (context == 'corporate') {
            search_custom_fields['search_by_key_corporate_custom_fields'] = customFieldQuery
          } else {
            search_custom_fields['search_by_key_custom_fields'] = customFieldQuery
          }

          requestParams['filterrific'] = _.merge(
            _requestParams['filterrific'], search_custom_fields
          )
        } else if(!isCustomField && filterByFilterrific){
          _requestParams['filterrific'][filterByFilterrific] = filter?.filterVal || ''
        }

      })
    } else if (type == 'sort'){
      let columnTable = _.find(bootstrapTable.current.props.columns, { 'dataField': args.sortField })
      let sortByFilterrific = columnTable?.sortByFilterrific

      if(sortByFilterrific){
        _requestParams['filterrific']['sorted_by'] = `${ sortByFilterrific }${ args.sortOrder }`
      }
    }

    setRequestParams(_requestParams);
  }
  return [requestParams, setFilterRequestParams];
}

export default useFilterRequestParams;